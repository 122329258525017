import * as React from "react";
import { Grid, Tab, Typography } from "@material-ui/core";
import { Skeleton, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { navigate } from "gatsby-link";
import { createEnumParam, NumberParam, useQueryParams } from "use-query-params";

import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { UserDetails } from "../features/users/UserDetails";
import { useUser } from "../features/users/hooks";
import { useIsMounted } from "../common/hooks";
import { UserTradesOffered } from "../features/users/UserTradesOffered";
import { CardFilterWidget } from "../features/cards/CardFilterWidget";
import { ProductResults } from "../features/products/ProductResults";

const UserPage = (props) => {
    const TabsEnumParam = createEnumParam([`sale`, `trade`]);
    const [query, setQuery] = useQueryParams({
        tab: TabsEnumParam,
        page: NumberParam,
    });
    const isMounted = useIsMounted();
    const userId = Number(props[`*`].split(`/`).shift());
    isMounted && !userId && navigate(`/`, { replace: true });
    const currUrl = `/user/${props[`*`]}`;
    const {
        isError,
        isFetching,
        isLoading,
        data: result,
        error,
    } = useUser(userId);

    const handleTabChange = (event, newValue) => {
        setQuery({ page: undefined, tab: newValue });
    };

    if (isMounted && !isFetching && !isLoading) {
        // Make sure we only use Canonical URLs
        const canonicalUrl = `/user/${userId}`;
        if (canonicalUrl !== currUrl) {
            // TODO: 301 for SEO
            navigate(canonicalUrl, { replace: true });
        }
    }
    const pageTitle = `Public profile: ${
        result ? result.data.company || result.data.screen_name : `...`
    }`;

    return isMounted ? (
        <Layout>
            <SEO
                keywords={[`ccg`, `collectible`, `user`, `game`, `trade`]}
                title={pageTitle}
            />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {isLoading ? (
                            <Skeleton
                                animation="pulse"
                                height={240}
                                variant="rect"
                            />
                        ) : isError ? (
                            <Typography style={{ color: `red` }}>
                                Failed loading: {error.message}
                            </Typography>
                        ) : (
                            <>
                                <UserDetails user={result.data} />
                                <CardFilterWidget gridContainerClasses="mt-4" />
                                <TabContext value={query.tab || `sale`}>
                                    <TabList
                                        aria-label="User card lists"
                                        className="mt-4"
                                        indicatorColor="primary"
                                        onChange={handleTabChange}
                                        textColor="primary"
                                    >
                                        <Tab label="For Sale" value="sale" />
                                        <Tab label="For Trade" value="trade" />
                                    </TabList>
                                    <TabPanel value="sale">
                                        <ProductResults
                                            filterConstants={{ u: userId }}
                                        />
                                    </TabPanel>
                                    <TabPanel value="trade">
                                        <UserTradesOffered userId={userId} />
                                    </TabPanel>
                                </TabContext>
                            </>
                        )}
                    </Grid>
                </Grid>
            </section>
        </Layout>
    ) : null;
};

export default UserPage;
