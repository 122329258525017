import * as React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Avatar as MuiAvatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { directusClient } from "../../hooks/useDirectus";

export const Avatar = ({ fileId, muiAvatarClasses, size = `med`, variant }) => {
    const { isLoading, data: file } = useQuery(
        [`file`, fileId],
        () => {
            if (fileId === 0 || Number.isNaN(fileId)) {
                return Promise.resolve();
            }
            return directusClient.get(`files/${fileId}`);
        },
        { staleTime: 60000 }
    );

    if (isLoading) {
        return (
            <Skeleton
                animation="pulse"
                height={100}
                variant="rect"
                width={100}
            />
        );
    }

    return (
        <MuiAvatar
            className={`text-white ${muiAvatarClasses}`}
            style={{ height: `100px`, width: `100px` }}
            variant="square"
        >
            {fileId > 0 && file.data ? (
                <img
                    alt="avatar"
                    src={
                        file.data.filesize < 20000
                            ? file.data.data.full_url
                            : file.data.data.thumbnails[2].url
                    }
                />
            ) : null}
        </MuiAvatar>
    );
};

Avatar.propTypes = {
    fileId: PropTypes.number,
    muiAvatarClasses: PropTypes.string,
    size: PropTypes.oneOf([`large`, `med`, `small`]),
    variant: PropTypes.oneOf([`circle`, `circular`, `rounded`, `square`]),
};
