import * as React from "react";
import { Link } from "gatsby";
import {
    Button,
    Card,
    CardContent,
    IconButton,
    Typography,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";

import { FlagIcon } from "../../components/FlagIcon";
import { Avatar } from "./Avatar";
import { useUser } from "./hooks";

export const UserDetails = ({ user, paperClasses }) => {
    const currentUser = useUser();
    const isMyProfile = !!(
        currentUser &&
        currentUser.data &&
        Number(currentUser.data.data.id) === Number(user.id)
    );

    return (
        <Card
            className={`flex flex-col flex-wrap justify-center sm:flex-row ${paperClasses}`}
        >
            <div className="flex-initial">
                <Avatar
                    fileId={Number(user.avatar)}
                    style={{ height: `100px`, width: `100px` }}
                />
            </div>
            <div className="flex-auto">
                <CardContent>
                    <h1 className={`text-2xl uppercase`}>
                        <FlagIcon
                            className="align-baseline h-5 mr-2"
                            code={user.country || `?`}
                            type="country"
                        />
                        {` `}
                        {user.company || user.screen_name}
                        {isMyProfile && (
                            <IconButton
                                aria-label="edit profile"
                                color="primary"
                                component={Link}
                                to="/dashboard/account/edit-profile/"
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </h1>
                    <Typography
                        color="textSecondary"
                        variant="subtitle1"
                    ></Typography>
                    {!isMyProfile && (
                        <Button
                            aria-label="message user"
                            className=""
                            component={Link}
                            startIcon={<EmailIcon />}
                            to={`/dashboard/messages/new/${user.id}`}
                        >
                            Message user
                        </Button>
                    )}
                </CardContent>
            </div>
            <CardContent className="w-full">
                <strong>Bio: </strong>
                {user.bio}
            </CardContent>
        </Card>
    );
};
